import { render, staticRenderFns } from "./gx.vue?vue&type=template&id=70b37618&scoped=true&"
import script from "./gx.vue?vue&type=script&lang=js&"
export * from "./gx.vue?vue&type=script&lang=js&"
import style0 from "./gx.vue?vue&type=style&index=0&id=70b37618&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70b37618",
  null
  
)

export default component.exports