<template>
  <div>
    <pcHeaders></pcHeaders>
    <div class="headBanner">
      <div class="headCenter">
        <div>
          <img style="width:600px;height:320px"
               src="../../assets/images/headBannerImg.png"
               alt="">
          <div style="font-size: 14px;color: #666666;margin-top: 15px">培训平台：安徽继续教育网（联系方式：400-8816-822）</div>
        </div>
        <div class="rightBox">
          <div style="height: 22px;line-height: 22px;display: flex;font-size: 22px">
            <div>
              <img style="width:6px;height:22px"
                   src="../../assets/images/textLeft.png"
                   alt="">
            </div>
            <div style="color: #FE8000;font-weight: 600;">公需课</div>
            <div><img style="width:6px;height:22px"
                   src="../../assets/images/textRight.png"
                   alt=""></div>
            <div style="font-weight: 600;margin-left: 10px">碳达峰碳中和与绿色发展</div>
          </div>

          <div style="display: flex;margin-top:20px;font-size: 14px;color: #333333;">
            <div>科目类型：公需科目</div>
            <div style="margin-left: 20px">年度：2023年度</div>
            <div style="margin-left: 20px">学时：30学时</div>
          </div>
          <div style="display: flex;margin-top:15px;font-size: 14px;color: #333333;">
            <div>报名时间：2023.04.01 — 2024.03.31</div>
          </div>
          <div style="display: flex;margin-top:15px;font-size: 14px;color: #333333;">
            <div>培训形式：网络培训班</div>
          </div>
          <div style="margin-top:15px;font-size: 14px;color:#666666;width: 575px">
            课程意义：通过开展继续教育培训，形成以需求为导向，政府引导与单位自主相结合，个人自觉参加学习的继续教育运行机制，实现专业技术人员全员继续教育，知识结构及时更新，创新能力全面提高。对于保障专业技术人员继续教育权益，不断提升专业技术人员能大质，加强专业技术人员队伍建设具有重要意义。
          </div>
          <div style="display: flex;margin-top:21px;font-size: 14px;color: #333333;">
            <div @click="signUpNow()"
                 style="width: 150px;height: 48px;background: #2978FF;line-height: 48px;text-align: center;color: #ffffff;cursor: pointer">
              立即报名
            </div>

          </div>
        </div>
      </div>
    </div>

    <div id="center_Box"
         class="center_Box1">

      <div style="margin-top: 18px;display: flex">

        <div class="center_Left_Box">
          <div style="border-bottom: 1px solid #EEEEEE;height: 54px;display: flex;color: #666666;font-size: 16px">
            <div id="classDetails"
                 :class="tabIndex == 1? 'clickActive' :''"
                 style="width: 104px;line-height: 54px;text-align: center;cursor: pointer"
                 @click="tabNav(1)">课程详情
            </div>
            <div id="classChapter"
                 style="width: 104px;line-height: 54px;text-align: center;margin-left: 30px;cursor: pointer"
                 :class="tabIndex == 2? 'clickActive' :''"
                 @click="tabNav(2)">
              课程章节
            </div>
          </div>

          <div id="leftCenter"
               v-show="tabIndex == 1">
            <img style="width: 750px;height: 3019px;margin-top: 15px"
                 src="../../assets/images/centerLeft.png"
                 alt="">
          </div>

          <!-- 课程章节内容 -->
          <div id="classChapterCenter"
               style="margin-top: 15px;"
               v-show="tabIndex == 2">
            <div style="width: 745px;line-height: 32px;margin-bottom: 30px"
                 v-for="(item,index) in tmInfo.resCouCwList"
                 :key="index">
              <div style="font-size: 16px;color: #333333;font-weight: 600">第{{ index+1 }}章</div>
              <div style="display: flex;justify-content: space-between;font-size: 14px;color: #999999">
                <div style="width: 378px;">

                  {{ item.CW_NAME }}

                </div>
                <div>学时：{{ item.CREDITNUM }}</div>
                <div @click="sts(item)"
                     style="cursor: pointer;width: 80px;height: 32px;border: 1px solid #FE8000;opacity: 1;border-radius: 19px;line-height: 32px;text-align: center;color: #FE8000">
                  试听
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="center_Right_Box">
          <div style="border-bottom: 1px solid #EEEEEE;height: 54px;display: flex;color: #666666;font-size: 16px">
            <div class="clickActive"
                 style="width: 104px;line-height: 54px;text-align: center;cursor: pointer">
              报名流程
            </div>

          </div>
          <img style="width: 353px;height: 602px;margin-top: 15px"
               src="../../assets/images/centerRight.png"
               alt="">

          <div style="position: absolute;top: 309px;box-sizing: border-box;padding: 0 32px">
            <div class="rightBox1">
              <div style="height: 34px;line-height: 34px;display: flex">
                <img style="width: 34px;height: 100%"
                     src="../../assets/images/rightTop.png"
                     alt="">
                <span style="margin-left: 5px;font-weight: 600">报名流程</span>
              </div>
              <div style="margin-top: 12px;font-size: 14px">
                登录 <span style="color: #F78041">安徽省专业技术人员继续教育管理平台</span> -选择专题去报名-到培训平台缴费。
              </div>
            </div>

            <div class="rightBox1"
                 style="margin-top: 17px">
              <div style="height: 34px;line-height: 34px;display: flex">
                <img style="width: 34px;height: 100%"
                     src="../../assets/images/rightTop.png"
                     alt="">
                <span style="margin-left: 5px;font-weight: 600">学习流程</span>
              </div>
              <div style="margin-top: 12px;font-size: 14px">
                登录管理平台—报名记录点击“去学习”,或报名成功后直接登录培训平台—完成课程学习并通过考试—到管理打印电子证书
              </div>
            </div>

            <div style="margin-top: 33px;color: #4686F7;font-size: 14px;text-align: center">—安徽继续教育网—</div>
          </div>
        </div>
      </div>
    </div>
    <pcBottom></pcBottom>
    <el-dialog title="提示"
               :visible.sync="dialogVisible"
               width="30%">
      <video id="videoPlayer"
             style="width: 100%;"
             class="video-js videoBox"
             muted></video>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="closeVideo">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import { getTMUrl } from '@/api/index.js';
import Videojs from 'video.js';
import 'videojs-contrib-hls';
import '@videojs/http-streaming';
// import { login } from '@/api/login.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      tabIndex: 1,
      tmInfo: {},
      dialogVisible: false,
      videoUrl: '',
      options: {
        autoplay: true, // 设置自动播放
        muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音 （Chrome66及以上版本，禁止音视频的自动播放）
        preload: 'auto', // 预加载
        controls: true // 显示播放的控件
      },
      player: null
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //关闭
    closeVideo() {
      this.dialogVisible = false
      this.player.pause();
    },
    getVideo(videoUrl) {
      this.player = Videojs('videoPlayer', this.options);
      if (/\.m3u8$/.test(videoUrl)) {
        //判断视频源是否是m3u8的格式
        this.player.src([
          {
            src: videoUrl,
            type: 'application/x-mpegURL' // 告诉videojs,这是一个hls流
          }
        ]);
      } else {
        let url = videoUrl + '.mp4';
        this.player.src(url);
      }
      let _this = this;
      this.player.on('loadstart', function () {
        console.log('开始请求数据 ');
      });
      this.player.on('progress', function () {
        console.log('正在请求数据 ');
      });
      this.player.on('loadedmetadata', function () {
        console.log('获取资源长度完成 ');
      });
      this.player.on('canplaythrough', function () {
        console.log('视频源数据加载完成');
      });
      this.player.on('waiting', function () {
        console.log('等待数据');
      });
      this.player.on('play', function () {
        console.log('视频开始播放');
      });
      this.player.on('playing', function () {
        console.log('视频播放中');
      });
      this.player.on('pause', function () {
        console.log('视频暂停播放');
      });
      this.player.on('ended', function () {
        console.log('视频播放结束');
      });
      this.player.on('error', function () {
        console.log('加载错误');
      });
      this.player.on('seeking', function () {
        console.log('视频跳转中');
      });
      this.player.on('seeked', function () {
        console.log('视频跳转结束');
      });
      this.player.on('ratechange', function () {
        console.log('播放速率改变');
      });
      this.player.on('timeupdate', function (e) {
        console.log('播放时长改变', _this.player.currentTime());
        if (_this.player.currentTime() >= 300) {
          _this.$message('试听5分钟结束，请购买完整课程进行学习！');
          _this.player.currentTime(0);
          return false;
        }
      });
      this.player.on('volumechange', function () {
        console.log('音量改变');
      });
      this.player.on('stalled', function () {
        console.log('网速异常');
      });
    },
    //试听
    sts(item) {
      console.log(item);
      this.dialogVisible = true
      this.$nextTick(() => {
        this.getVideo(item.LAUNCH);
      })
    },
    tabNav(id) {
      this.tabIndex = id;
    },
    //公需课报名
    signUpNow() {
      window.open(this.tmInfo.tmUrl, '_blank');
    },
    getTMUrl() {
      getTMUrl({}).then(res => {
        console.log(res.data.data);
        this.tmInfo = res.data.data;
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTMUrl();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeDestroy() {
    if (this.player) {
      this.player.dispose(); //重置videojs的内部状态并移除dom
    }
  }
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.headBanner {
  width: 100%;
  height: 420px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 0;
  display: flex;
  justify-content: center;
}
.headCenter {
  display: flex;
}
.rightBox {
  margin-left: 34px;
  margin-top: 5px;
}
.center_Box1 {
  position: relative;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  padding-bottom: 92px;
}
.center_Box1 .center_Left_Box {
  width: 790px;
  /* height: 1650px; */
  background: #ffffff;
  border-radius: 0px;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
}
.center_Box1 .center_Right_Box {
  width: 392px;
  height: 700px;
  background: #ffffff;
  margin-left: 18px;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  position: relative;
}
.clickActive {
  border-bottom: 1px solid #2978ff;
  color: #2978ff;
}
.rightBox1 {
  width: 290px;
  /* height: 120px; */
  background: rgba(41, 120, 255, 0.2);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 15px 20px 20px 15px;
  color: #165ed9;
  font-size: 16px;
}
</style>